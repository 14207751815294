import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import collapse from '@alpinejs/collapse';
import Alpine from 'alpinejs';
import shuffleLetters from 'shuffle-letters';

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
// gsap.ScrollToPlugin(ScrollTrigger);
console.log(ScrollToPlugin)




const isSmartPhone = () => {
  if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
    return true;
  } else {
    return false;
  }
};

const scrollAccordion = () => {

  const containar = document.querySelector(".js-scroll-accordions");
  const scrollAccordions = document.querySelectorAll(".js-scroll-accordion");
  const scrollAccordionFirst = document.querySelector(".js-scroll-accordion.is-first");
  if (!containar || !scrollAccordions) {
    return;
  }
  let mm = gsap.matchMedia();

  mm.add("(min-width: 768px)", () => {


    const scrollScrub = 2.2;
    gsap.set(scrollAccordions, { overflow: "hidden" });
    gsap.set(scrollAccordions, { height: 0 });
    gsap.set(scrollAccordionFirst, { height: "auto" });
    gsap.from(".js-acrcordion-progress", {
      scaleY: 0,
      transformOrigin: "top center",
      ease: "none",
      scrollTrigger: {
        trigger: containar,
        scrub: scrollScrub,
        start: "top top",
        // end: "bottom top",
        end: "+=4000",

      }
    })
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: containar,
        scrub: scrollScrub,
        start: "top top",
        end: "+=4000",
        // snap: 1,
        // markers: true,
        pin: true
      }
    });
    let animationOptions1 = {
      height: "auto",
      duration: 1,
      delay: 0.05,
      opacity: 1,
      ease: "none",
    };
    let animationOptions2 = {
      height: 0,
      duration: .5,
      opacity: 0,
      delay: .5,
      ease: "none",
    };

    // gsap.to(".progress", {
    //   value: 100,
    //   ease: "none",
    //   scrollTrigger: { scrub: 0.3 }
    // });
    const progressNum = document.querySelector(".js-scroll-accordion-progress-num")
    const updateActive = (num) => {
      progressNum.textContent = ('00' + num).slice(-2);
    };

    timeline
      .to(scrollAccordions[0], {
        ...animationOptions2,
        onUpdate: () => { updateActive(1); },
      })
      .to(scrollAccordions[1], {
        ...animationOptions1,
        onUpdate: () => { updateActive(2); },
      }, '<',)

      .to(scrollAccordions[1], animationOptions2)
      .to(scrollAccordions[2], {
        ...animationOptions1,
        onUpdate: () => { updateActive(3); },
      }, '<')

      .to(scrollAccordions[2], animationOptions2)
      .to(scrollAccordions[3], {
        ...animationOptions1,
        onUpdate: () => { updateActive(4); },
      }, '<')

      .to(scrollAccordions[3], animationOptions2)
      .to(scrollAccordions[4], {
        ...animationOptions1,
        onUpdate: () => { updateActive(5); },
      }, '<')

      // .to(scrollAccordions[4], animationOptions2)
      // .to(scrollAccordions[5], {
      //   ...animationOptions1,
      //   onUpdate: () => { updateActive(6); },
      // }, '<')
    // gsap.set(scrollAccordionFirst, { height : "auto" });
  });


};
scrollAccordion()






const sectionTitleElement = gsap.utils.toArray('.js-enter-active');
if (sectionTitleElement) {
  let animationStart = 'top center';
  if (isSmartPhone()) {
    animationStart = 'top bottom';
  }
  sectionTitleElement.forEach((element, index) => {
    gsap.to(element, {
      scrollTrigger: {
        trigger: element,
        start: animationStart,
        onEnter: () => element.classList.add('is-active'),
      }
    });
  });
}
const markanimes1 = gsap.utils.toArray('.js-mark-anime-x1');
if (markanimes1) {
  markanimes1.forEach((element, index) => {
    gsap.to(element, {
      scaleX: 0,
      repeat: -1,
      duration: 1.5,
      yoyo: true,
    });
  });
}
const markanimes2 = gsap.utils.toArray('.js-mark-anime-y1');
if (markanimes2) {
  markanimes2.forEach((element, index) => {
    gsap.to(element, {
      scaleY: 0,
      repeat: -1,
      duration: 1.5,
      yoyo: true,
    });
  });
}
const markanimes3 = gsap.utils.toArray('.js-mark-anime-flash1');
if (markanimes3) {
  markanimes3.forEach((element, index) => {
    gsap.to(element, {
      opacity:0,
      repeat: -1,
      duration: 1.5,
      yoyo: true,
    });
  });
}
const markanimes4 = gsap.utils.toArray('.js-mark-anime-flash2');
if (markanimes4) {
  markanimes4.forEach((element, index) => {
    gsap.to(element, {
      yPercent: 100,
      repeat: -1,
      duration: 1,
      yoyo: true,
    });
  });
}
const markanimes5 = gsap.utils.toArray('.js-mark-anime-flash3');
if (markanimes5) {
  markanimes5.forEach((element, index) => {
    gsap.to(element, {
      yPercent: -100,
      repeat: -1,
      duration: 1,
      yoyo: true,
    });
  });
}




function spanWrap() {
  const target = document.querySelector('.js-span-wrap-text');
  if (!target) {
    return
  }
  const nodes = [...target.childNodes];

  let spanWrapText = ""

  nodes.forEach((node) => {
    if (node.nodeType == 3) {//テキストの場合
      const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
      //spanで囲んで連結
      spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
        return acc + `<span>${v}</span>`
      }, "");
    } else {//テキスト以外
      //<br>などテキスト以外の要素をそのまま連結
      spanWrapText = spanWrapText + node.outerHTML
    }
  })

  target.innerHTML = spanWrapText
}

spanWrap();

function divWrap() {
  const targets = document.querySelectorAll('.js-div-wrap-text');
  if (!targets) {
    return
  }
  targets.forEach((target) => {
    const nodes = [...target.childNodes];

    let spanWrapText = ""

    nodes.forEach((node) => {
      if (node.nodeType == 3) {//テキストの場合
        const text = node.textContent.replace(/\r?\n/g, '');//テキストから改行コード削除
        //spanで囲んで連結
        spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
          return acc + `<div>${v}</div>`
        }, "");
      } else {//テキスト以外
        //<br>などテキスト以外の要素をそのまま連結
        spanWrapText = spanWrapText + node.outerHTML
      }
    })
    target.innerHTML = spanWrapText
  })

}

divWrap();


window.addEventListener("load", function() {
  window.addEventListener("pageshow", function (event) {
    var entries = performance.getEntriesByType("navigation");
    entries.forEach(function (entry) {
      if (entry.type == "back_forward") {
        let url = sessionStorage.getItem('url');
        if(url.indexOf("confirm") > -1){
          gsap.to(window, { duration: 2, scrollTo: '#contact' });
        }
      }
    });
  });
})




